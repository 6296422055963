// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

;globalThis["_sentryRewritesTunnelPath"] = "/monitoring";globalThis["SENTRY_RELEASE"] = {"id":"250114.5b3378d.production"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from '@sentry/nextjs';
import { ignoreErrors } from '@trmediaab/sentry-helpers';

Sentry.init({
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  debug: false,

  // Send a sample of transactions in production for performance monitoring
  tracesSampleRate: process.env.VERCEL_ENV === 'production' ? 0.005 : 0,

  // The sample rate for replays that are recorded when an error happens.
  replaysOnErrorSampleRate: process.env.VERCEL_ENV === 'production' ? 0.05 : 0,

  // The sample rate for replays that begin recording immediately and last the entirety of the user's session.
  replaysSessionSampleRate: process.env.VERCEL_ENV === 'production' ? 0.005 : 0,

  // You can remove this option if you're not planning to use the Sentry Session Replay feature:
  integrations: [
    Sentry.replayIntegration({
      // Additional Replay configuration goes in here, for example:
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],

  // Ignore unactionable errors
  ignoreErrors,

  // Allow only errors originating from
  // - spelvärde production domain
  // - Vercel preview domains for spelvärde
  allowUrls: [
    /https:\/\/(www\.)?trmedia\.se/,
    /https:\/\/trmedia(\S+)?\.trdev\.se/,
  ],
});
